body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
	font-family: "SuffolkPunch";
	src:local("SuffolkPunch"),
		url("./suffolkpunch.ttf") format("truetype");
	font-weight: bold;
}

.font-face-gm {
	font-family: "SuffolkPunch";
}

.menu {
	position: relative;
	overflow: hidden;
}

.menu a {
	float: left;
	text-align: center;
	padding: 30px 100px;
	text-decoration: none;
	color: white;
	font-size: 20px;
}

.menu-center a {
	float: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 30px;
	color: #a0a081;
}

.menu-right {
	float: right;
}

#background {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: -1;
}

#countdown {
	font-family: "SuffolkPunch";
	color: white;
	font-size: 300px;
	font-variant: petite-caps; 
	list-style: none;
	display: flex;
	text-align: center;
	letter-spacing: 15px;
	line-height: 0.2em;
	margin-top: 50%;
}

#countdown li {
	padding: 0 20px;
	float: left;
}

.newAlbum {
	position: absolute;
    padding-top: 55px;
    padding-left: 85px;
    color: white;
    font-size: 40px;
    font-family: "SuffolkPunch";
    color: #a0a081;
}

.newAlbum h1 {
	padding-top: 0;
    margin: 0;
    color: white;
}

#countdown a {
	font-size: 100px;
	letter-spacing: 0;
}

.scroll-container {	
    width: 530px;
    color: white;
    overflow: hidden;
    margin: auto;
}

.wrapper {
	height: 550px;
}

.button {
	background-color: #a0a081;
	color: black;
	padding: 22px 40px;
	font-family: "SuffolkPunch";
	border: 5px solid wheat;
	text-decoration: none;
}

.button-wrapper {
    position: relative;
    top: 55px;
}

.button-wrapper a {
	float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@media only screen and (max-width: 1300px) {
 .menu a {
 	padding: 18px;
    padding-top: 25px;
 }
}